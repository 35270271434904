<template>
    <div class="albums">
        <PageNavBar :title="categoryTitle" :goBack="goBackAndRemoveKeepAlive"/>

        <album-pull-list :categoryId="categoryId" ></album-pull-list>
    </div>
</template>

<script>
import PageNavBar from '../../components/PageNavBar'
import AlbumPullList from '../../components/AlbumPullList'
import { keepAliveMixin } from '../../mixins'

export default {
    name: 'AlbumPage',
    mixins: [keepAliveMixin],
    components: {
        PageNavBar,
        AlbumPullList
    },
    props: {
    },
    setup() {
    },
    mounted() {
    },
    data() {
        return {
            categoryId: '0',
            categoryTitle: ''
        }
    },
    created() {
        let { id, title } = this.$route.params
        this.categoryId = id
        this.categoryTitle = title
    },
    methods: {
    }
}
</script>

<style lang="stylus" scoped>
</style> 